import dayjs from 'dayjs'
import { Nullable } from './customtypes.util'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(utc)
dayjs.extend(relativeTime)

export default class DateUtil {
  public static format (
    date: Nullable<dayjs.Dayjs>,
    format?: string
  ): Nullable<string> {
    return date?.format(format)
  }

  public static getUtcNow (): dayjs.Dayjs {
    return dayjs.utc()
  }

  public static formatUtcString (
    dateString: string,
    format?: string
  ): Nullable<string> {
    return dayjs
      .utc(dateString)
      .local()
      .format(format ?? 'DD MMM YYYY')
  }

  public static parseUtcString (dateString: string): Date {
    return dayjs.utc(dateString).local().toDate()
  }

  public static timeAgo (dateString: string): string {
    return dayjs.utc(dateString).local().fromNow(true)
  }

  public static isEqualTime (date1: string, date2: string): boolean {
    const time1 = this.formatUtcString(date1, 'HH:mm')
    const dateTime1 = dayjs(`2000-01-01 ${time1}`)

    const time2 = this.formatUtcString(date2, 'HH:mm')
    const dateTime2 = dayjs(`2000-01-01 ${time2}`)

    return dateTime1.diff(dateTime2, 'm', true) === 0
  }

  public static toLiteral () {
    return {
      format: this.format,
      getUtcNow: this.getUtcNow,
      formatUtcString: this.formatUtcString,
      parseUtcString: this.parseUtcString,
      timeAgo: this.timeAgo,
      isEqualTime: this.timeAgo
    }
  }
}
