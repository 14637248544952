enum Actions {
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  SIGNUP = 'signup',
  SIGNUP_GUEST = 'signup_gust',
  LOGOUT = 'logout',

  SET_FAVORITE_PRODUCTS = 'setFavoriteProductsAction',
  CLEAR_MENU_CACHE = 'clearAllMenuCache',

  SHOW_PRODUCT_DETAIL = 'showProductDetailDialog',
  SHOW_ERROR = 'showErrorDialog',
  SHOW_TIP = 'showTipDialog',
  SHOW_UNLOCK = 'showUnlockDialog',
  HIDE_ALL_DIALOG = 'hideAllDialogsAction',
  HIDE_ALL_ERRORS = 'hideAllErrorDialogsActions',

  UPDATE_CONFIG = 'updateCartConfig',
  UPDATE_BRANCH = 'updateBranchConfig',
  SET_CLIENT_PHONE = 'setClientPhoneAction',
  SET_CART_INFO = 'setCartInfo',
  UPDATE_CART = 'updateCartObject',
  UPDATE_CART_TOTALS = 'updateCartTotals',
  SET_ORDER_IS_PICKUP = 'setOrderIsPickUpAction',
  SET_ORDER_IS_DINEIN = 'setOrderIsDineInAction',

  CART_EMPTY = 'cart_empty_product',
  CART_CLEAR_ALL = 'cart_clear_all',

  SET_PENDING_PLACE = 'setPendingPlaceAction',
  SET_BANNERS = 'setBannersAction',

  SET_GENERAL_CONFIG = 'setGeneralConfigAction'
}

enum Mutations {
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',

  SET_VISIBLE_DIALOG = 'setVisibleDialog',
  SET_VISIBLE_ERROR_DIALOG = 'setVisibleErrorDialog',
  HIDE_ALL_DIALOG = 'hideAllDialogsMutation',
  HIDE_ALL_ERRORS = 'hideAllErrorDialogsMutation',

  SET_FAVORITE_PRODUCTS = 'setFavoriteProducts',

  SET_CART_BRANCH = 'setCartBranch',
  SET_CLIENT_PHONE = 'setClientPhone',
  SET_ORDER_IS_PICKUP = 'setOrderIsPickUp',
  SET_ORDER_IS_DINEIN = 'setOrderIsDineIn',
  SET_CART = 'setCart',
  SET_CART_TOTALS = 'setCartTotals',

  SET_PENDING_PLACE = 'setPendingPlaceMutation',
  SET_BANNERS = 'setBannersMutation',

  SET_BRANCH_ID = 'setBranchIDConfig',
  SET_UNLOCK_PIN = 'setUnlockPin',
  SET_PINPAD_TYPE = 'setPinpadType',
  SET_PINPAD_FISERV = 'setPinpadFiserv',
  SET_PINPAD_MIT_DLL = 'setPinpadMit',
  SET_PINPAD_MIT_WEB = 'setPinpadMitWeb',
  SET_PRINTER_CONFIG = 'setPrinterConfig'
}

export { Actions, Mutations }
