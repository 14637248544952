import authAxios from '@/utils/authaxios.util'
import ServiceHandler from '@/utils/servicehandler.util'
import appconfigService from '../appconfig/appconfig.service'
import ServiceConfig from '@/config/services.config'
import { Nullable } from '@/utils/customtypes.util'
import Kiosk from '@/models/kiosk/kiosk.model'
import KioskResponse from '@/models/responses/kiosk-config.response'

class KioskService {
  public async getConfig (id: Nullable<string>): Promise<Kiosk> {
    try {
      const appConfig = await appconfigService.get()
      const serviceUrl = appConfig.getServiceUrl(ServiceConfig.KIOSK_CONFIG_GET)

      const resp = await authAxios
        .post(serviceUrl, {
          Data: {
            KioskId: id
          }
        })
        .then(ServiceHandler.checkSuccess<KioskResponse>(KioskResponse))
        .catch(ServiceHandler.catchError())

      return resp.Kiosk as Kiosk
    } catch (err) {
      console.log(err)
      return Promise.reject(err)
    }
  }
}

export const kioskService = new KioskService()
