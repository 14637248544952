import store from '@/store'
import { EscPosUtil } from './providers/esc-pos.util'
import { StarUtil } from './providers/star.util'

export enum PrintLanguageTypeEnum {
  ESC_POS = 'esc-pos',
  STAR = 'star'
}

export interface PrintLanguageProvider {
  getQzPrintData(text: string): string[]
}

export class PrintLanguageFactory {
  public static getForType (type: PrintLanguageTypeEnum) {
    switch (type) {
      case PrintLanguageTypeEnum.STAR:
        return new StarUtil()
      default:
        return new EscPosUtil()
    }
  }

  public static getProvider () {
    const config = store.getters.printerConfig
    const type = config?.type as PrintLanguageTypeEnum | null

    return PrintLanguageFactory.getForType(
      type ?? PrintLanguageTypeEnum.ESC_POS
    )
  }
}

export const getPrintLanguageUtil = PrintLanguageFactory.getProvider
