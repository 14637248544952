import { PrintLanguageProvider } from '../print-language-provider'

export class StarUtil implements PrintLanguageProvider {
  getQzPrintData (text: string): string[] {
    const data = text.split('\n').map((line) => `${line.trim()}\n`)

    data.push(...['\n', '\n'])
    data.push('\x1b' + '\x64' + '\x02')

    return data
  }
}
