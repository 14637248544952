import { PrintLanguageProvider } from '../print-language-provider'

export class EscPosUtil implements PrintLanguageProvider {
  getQzPrintData (text: string): string[] {
    const data = text
      .split('\n')
      .map((line) => `${line.trim()}\n${'\x1B' + '\x61' + '\x30'}`)

    data.push('\x1B' + '\x61' + '\x30') // left align
    data.push('\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A')

    data.push('\x1D' + '\x56' + '\x00')
    data.push('\x10' + '\x14' + '\x01' + '\x00' + '\x05')

    return data
  }
}
