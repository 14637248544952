/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { getPrinterService } from '@/utils/printer/printer'
import {
  PaymentInputModel,
  PaymentResponseModel,
  RefundInputModel,
  RefundResponseModel
} from '../models/payment.model'
import { PaymentProvider } from '../payment-provider'

import { Pinpad } from '@/utils/payment/vendor/pinpad'
import { PinPadConfigMitWeb } from '@/store/modules/config.module'
import { Nullable } from '@/utils/customtypes.util'
import store from '@/store'

export class MitWebUtil implements PaymentProvider {
  config = ((): Nullable<PinPadConfigMitWeb> => {
    try {
      return store.getters.pinpadConfigMitWeb
    } catch (err) {
      return null
    }
  })()

  pinpadService = ((): Pinpad => {
    return new Pinpad(
      this.config?.id ?? '',
      this.config?.environment ?? 'QA',
      this.config?.username ?? '',
      this.config?.password ?? ''
    )
  })()

  get initOnStart (): boolean {
    return !(this.config?.ignoreOnStart ?? false)
  }

  get requiresInteractiveCancel (): boolean {
    return false
  }

  async init (): Promise<object> {
    if (!this.config) {
      return Promise.reject('Falta realizar configuración de pinpad')
    }

    return this.pinpadService.init().then((data) => {
      return data as object
    })
  }

  executePayment (data: PaymentInputModel): Promise<PaymentResponseModel> {
    return this.pinpadService
      .readCard(data.amount + data.tip, data.reference, [])
      .then(async (response) => {
        console.log(response)

        const responseData = await this.pinpadService
          .sendToken(response)
          .then((response) => {
            console.log(response)

            return {
              transactionId: response.foliocpagos,
              reference: response.reference,
              authorization: response.auth,
              total: +response.amount,
              amount: data.amount,
              tip: data.tip,
              cardType: response.cc_type,
              date: response.date,
              ticket: this.pinpadService.verifyVoucher(response.voucher_cliente)
            }
          })

        if (responseData.ticket) {
          const printerService = getPrinterService()
          printerService
            .getConfig()
            .then((config) => {
              printerService.print(config, responseData.ticket ?? '')
            })
            .catch((err) => {
              console.log(err)
              // ignore
            })
        }

        return responseData
      })
  }

  cancel (data: RefundInputModel): Promise<RefundResponseModel> {
    return this.pinpadService
      .cancel(data.transactionId, data.authorization, data.amount + data.tip)
      .then((response: any) => {
        const voucher = this.pinpadService.verifyVoucher(response.VoucherCliente)

        return {
          transactionId: data.transactionId,
          reference: data.reference,
          authorization: data.authorization,
          amount: data.amount,

          ticket: voucher
        }
      })
  }
}
