
import { computed } from '@vue/runtime-core'
import { Nullable } from 'primevue/ts-helpers'
import { Options, prop, setup, Vue } from 'vue-class-component'
import VueLoadImage from 'vue-load-image'

class ImageLoadProps {
  image = prop<string>({})
  imageClass = prop<string>({})

  placeholder = prop<string>({})
  placeholderClass = prop<string>({})

  background = prop<boolean>({ default: false })
}

@Options({
  name: 'image-load',
  components: {
    VueLoadImage
  }
})
export default class ImageLoad extends Vue.with(ImageLoadProps) {
  context = setup(() => {
    // const publicPath = process.env.BASE_URL
    const placeHolderUrl = computed<Nullable<string>>(() => {
      if (this.$props.placeholder?.startsWith('http')) {
        return this.$props.placeholder
      }

      // return `${publicPath}${this.$props.placeholder}`
      return this.$props.placeholder
    })

    return {
      placeHolderUrl
    }
  })
}
