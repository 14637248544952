import store from '@/store'
import axios from 'axios'

interface LocalManagementConfig {
  endpoint: string
}

export class LocalManagementUtil implements LocalManagementConfig {
  endpoint = ((): string => {
    try {
      return (
        store.getters.pinpadConfigFiserv?.endpoint ?? 'http://localhost:5000'
      )
    } catch (err) {
      return 'http://localhost:5000'
    }
  })()

  get unlockEndpoint (): string {
    return `${this.endpoint}/api/v1/kiosk/logout`
  }

  unlock (): Promise<void> {
    return axios
      .delete<object>(this.unlockEndpoint)
      .then(() => {
        // ignored
      })
      .catch((error) => {
        console.log(error)
        return Promise.reject(error.detail ?? error.title ?? error.message)
      })
  }
}
