import axios from 'axios'
import ServiceHandler from '@/utils/servicehandler.util'
import ServiceResponse from '@/utils/models/serviceresponse.model'
import GeneralConfig from '@/config/general.config'
import AppConfig from '@/models/appconfig/appconfig.model'
import { Nullable } from '@/utils/customtypes.util'

class AllSectionsService {
  private static fetchPromise: Nullable<Promise<AppConfig>>

  private fetchConfig (): Promise<AppConfig> {
    if (AllSectionsService.fetchPromise) {
      return AllSectionsService.fetchPromise
    }

    console.info('AppConfig expirado, volviendo a descargar...')
    AllSectionsService.fetchPromise = axios
      .post<ServiceResponse<AppConfig>>('/api/app/getConfig', {})
      .then(
        ServiceHandler.checkSuccess<AppConfig>(AppConfig, (data: AppConfig) => {
          data.timestamp = new Date().getTime()
          data.appVersion = GeneralConfig.APP_VERSION

          localStorage.setItem('allSections', JSON.stringify(data))
        })
      )
      .then((data) => {
        AllSectionsService.fetchPromise = null
        return data
      })
      .catch(ServiceHandler.catchError())

    return AllSectionsService.fetchPromise
  }

  public async get (): Promise<AppConfig> {
    const allSectionsJson = JSON.parse(
      localStorage.getItem('allSections') ?? 'null'
    )
    if (allSectionsJson) {
      const allSections = new AppConfig().from(allSectionsJson)

      if (allSections.isValid()) {
        return Promise.resolve(allSections)
      }
    }

    return this.fetchConfig()
  }
}

export default new AllSectionsService()
