
import { Options, setup, prop, Vue } from 'vue-class-component'
import { Modifier, ModifierItem } from '@/models/menu/product.model'

import InputNumber from 'primevue/inputnumber'
import RadioButton from 'primevue/radiobutton'
import Checkbox from 'primevue/checkbox'

import ProductModifierItem from '@/components/products/ProductModifierItem.vue'
import { computed, reactive, toRefs } from 'vue'
import { Nullable } from 'primevue/ts-helpers'
import store from '@/store'
import { Actions } from '@/config/store.config'
import ModifierItemRequestModel from '@/models/requests/modifieritemadd.request'

class ProductItemProps {
  modifier = prop<Modifier>({ required: true })
  item = prop<ModifierItem>({ required: true })
}

interface ProductItemState {
  expanded: boolean
  childModifiers: ProductModifierItem[]
}

@Options({
  name: 'product-item',
  emits: ['item-expanded'],
  components: {
    InputNumber,
    RadioButton,
    Checkbox,
    ProductModifierItem
  }
})
export default class ProductItem extends Vue.with(ProductItemProps) {
  context = setup(() => {
    const state = reactive<ProductItemState>({
      expanded: false,
      childModifiers: []
    })

    const itemPrice = computed<number>(() => {
      const item = this.$props.item
      const childQuantity = this.getItemValue(item.Modifiers ?? [])

      return (childQuantity + (item.ItemPrice ?? 0)) * (item.ItemQuantity ?? 0)
    })

    return {
      ...toRefs(state),
      itemPrice
    }
  })

  public getMaxInput (item: ModifierItem, modifier: Modifier) {
    const itemMax =
      (item?.ItemMaxQuantity ?? 0) === 0 ? 99 : item?.ItemMaxQuantity ?? 0

    if (!modifier.ModifierMax) {
      return itemMax
    }

    const currentSelectedCount =
      modifier.SelectedItemList?.filter(
        (i) => i.ItemId !== item.ItemId
      )?.reduce((count, item) => {
        return count + (item.ItemSelectedQuantity ?? 0)
      }, 0) ?? 0

    const availableCount = Math.max(
      0,
      (modifier.ModifierMax ?? 0) - currentSelectedCount
    )
    return Math.min(availableCount, itemMax)
  }

  public onItemQuantityChange (item: ModifierItem): void {
    if (!this.$props.modifier) {
      return
    }

    const index = this.$props.modifier.SelectedItemList?.indexOf(item) ?? -1
    if (item.ItemSelectedQuantity <= 0 && index >= 0) {
      this.$props.modifier.SelectedItemList?.splice(index, 1)
      item.Modifiers = []
      this.cleanNestedModifiers(null)
      this.closeNestedModifiers(null)
      return
    }

    if (this.nestedItemsNedded(item) && !item.Modifiers?.length) {
      this.itemExpanded(item, true)
      item.ItemSelectedQuantity = 0

      return
    }

    if (item.ItemSelectedQuantity > 0 && index < 0) {
      this.$props.modifier.SelectedItemList?.push(item)
    }
  }

  public validateItem (item: ModifierItem): void {
    const modifier = this.$props.modifier
    if (!this.$props.modifier) {
      return
    }

    if (!this.nestedItemsNedded(item)) {
      this.$props.modifier.OldSelectedItem = this.$props.modifier.SelectedItem

      modifier.ItemList?.filter(
        (item) => item.ItemId !== modifier.SelectedItem?.ItemId
      ).forEach((item) => this.cleanNestedModifiers(item))

      return
    }

    item.ItemSelectedQuantity = 1
    this.$props.modifier.SelectedItem = this.$props.modifier.OldSelectedItem
    modifier.ItemList?.filter(
      (item) => item.ItemId !== modifier.SelectedItem?.ItemId
    ).forEach((item) => this.cleanNestedModifiers(item))

    this.itemExpanded(this.$props.item, true)
  }

  public onItemSelected (item: ModifierItem, modifier: Modifier): void {
    if (!modifier) {
      return
    }

    const max = modifier.ModifierMax ?? 0
    const current = modifier.SelectedItemList?.length ?? 0
    const index = modifier.SelectedItemList?.indexOf(item) ?? -1

    if (index >= 0 && max > 0 && current > max) {
      modifier.SelectedItemList?.splice(index, 1)
      item.Modifiers = []
      return
    }

    const itemSelected =
      modifier.SelectedItemList?.some((i) => i.ItemId === item.ItemId) ?? false
    if (!itemSelected) {
      item.Modifiers = []
      this.cleanNestedModifiers(null)
      this.closeNestedModifiers(null)
      return
    }

    if (!this.nestedItemsNedded(item)) {
      item.ItemSelectedQuantity = 1
      return
    }

    this.itemExpanded(this.$props.item, true)
    if (index >= 0) {
      modifier.SelectedItemList?.splice(index, 1)
    }
  }

  public nestedItemsNedded (item: ModifierItem) {
    if (!item.ModifierList?.length) return false

    return true
  }

  public toggleNestedModifiers () {
    if (!this.$props.item.ModifierList?.length) return
    this.itemExpanded(this.$props.item, !this.context.expanded, true)
  }

  public onItemExpanded () {
    this.$emit('item-expanded', this.$props.item)
  }

  public itemExpanded (item: ModifierItem, show: boolean, manual = false) {
    const modifier = this.$props.modifier

    if (
      show &&
      item.ItemId !== modifier.SelectedItem?.ItemId &&
      modifier.SelectedItemList?.every((i) => i.ItemId !== item.ItemId)
    ) {
      const max = modifier.ModifierMax ?? 0
      const current =
        modifier.SelectedItemList?.reduce((count, item) => {
          return count + (item.ItemSelectedQuantity ?? 0)
        }, 0) ?? 0

      const maxReached = manual ? current >= max : current > max
      if (max > 0 && maxReached) return
    }

    this.context.expanded = show
    if (!this.context.expanded) {
      this.closeNestedModifiers(null)
      return
    }

    this.fillNestedModifiers()
    this.$emit('item-expanded', item)
  }

  public closeNestedModifiers (sourceItem: Nullable<ModifierItem>) {
    const item = this.$props.item
    if (sourceItem?.ItemId === item.ItemId) return

    item.ModifierList?.forEach((modifier) => {
      modifier.SelectedItem = undefined
      modifier.OldSelectedItem = undefined
      modifier.SelectedItemList = []

      modifier.ItemList?.forEach((item) => {
        item.ItemSelectedQuantity = 0
      })
    })

    this.context.expanded = false
    this.context.childModifiers.forEach((modifier) =>
      modifier.collapseNestedModifiers(sourceItem)
    )
  }

  public cleanNestedModifiers (sourceItem: Nullable<ModifierItem>) {
    const item = this.$props.item
    if (sourceItem?.ItemId === item.ItemId) return

    item.Modifiers = []
    item.ModifierList?.forEach((modifier) => {
      modifier.SelectedItem = undefined
      modifier.OldSelectedItem = undefined
      modifier.SelectedItemList = []

      modifier.ItemList?.forEach((item) => {
        item.ItemSelectedQuantity = 0
      })
    })

    this.context.childModifiers.forEach((modifier) =>
      modifier.cleanNestedModifiers(sourceItem)
    )
  }

  public fillNestedModifiers () {
    const item = this.$props.item
    if (!item.Modifiers?.length) return

    this.context.childModifiers.forEach((modifier) =>
      modifier.fillNestedModifiers(item)
    )
  }

  public setSelected (sourceItem: Nullable<ModifierItem>) {
    if (!sourceItem) return

    const modifier = this.$props.modifier
    const item = this.$props.item
    item.Modifiers = sourceItem.Modifiers
    item.ItemSelectedQuantity = sourceItem.ItemSelectedQuantity
      ? sourceItem.ItemSelectedQuantity
      : 1

    if (modifier?.ModifierMin === 1 && modifier?.ModifierMax === 1) {
      modifier.SelectedItem = item
      modifier.OldSelectedItem = modifier.SelectedItem

      modifier.ItemList?.filter(
        (item) => item.ItemId !== modifier.SelectedItem?.ItemId
      ).forEach((item) => (item.Modifiers = []))
    }

    const index = modifier.SelectedItemList?.indexOf(item) ?? -1

    if (
      modifier?.ModifierIsMultipleQuantity &&
      !(modifier?.ModifierMin === 1 && modifier?.ModifierMax === 1) &&
      index < 0
    ) {
      this.$props.modifier.SelectedItemList?.push(item)
    }

    if (
      !modifier?.ModifierIsMultipleQuantity &&
      !(modifier?.ModifierMin === 1 && modifier?.ModifierMax === 1) &&
      index < 0
    ) {
      this.$props.modifier.SelectedItemList?.push(item)
    }
  }

  public confirmNestedModifiers () {
    const item = this.$props.item
    const validModifiers = item.ModifierList?.filter(this.isModifierValid) ?? []

    if (validModifiers.length !== item.ModifierList?.length) {
      store.dispatch(Actions.SHOW_ERROR, {
        title: 'Verifica tus modificadores',
        message:
          'Debes verificar la configración de los modificadores obligatorios',
        type: ''
      })
      return
    }

    const modifierList =
      validModifiers.flatMap((modifier) => {
        if (modifier.SelectedItem) {
          const modifierList: ModifierItemRequestModel[] = [
            {
              ItemId: modifier.SelectedItem.ItemId,
              ItemName: modifier.SelectedItem.ItemName,
              ItemPrice: modifier.SelectedItem.ItemPrice,
              ItemQuantity: 1,
              ModifierId: modifier.ModifierId,
              Modifiers: modifier.SelectedItem.Modifiers ?? []
            }
          ]

          return modifierList
        }

        const modifierList: ModifierItemRequestModel[] =
          modifier.SelectedItemList?.map((item) => ({
            ItemId: item.ItemId,
            ItemName: item.ItemName,
            ItemPrice: item.ItemPrice,
            ItemQuantity: item.ItemSelectedQuantity,
            ModifierId: modifier.ModifierId,
            Modifiers: item.Modifiers ?? []
          })) ?? []

        return modifierList
      }) ?? []

    this.$props.item.Modifiers = modifierList
    this.closeNestedModifiers(null)
    this.setSelected(item)
  }

  public isModifierValid (modifier: Modifier): boolean {
    if (!modifier) {
      return false
    }

    const min = modifier.ModifierMin ?? 0
    const max = modifier.ModifierMax ?? 0

    let selectedQuantity = modifier.SelectedItem ? 1 : 0

    if (modifier.SelectedItemList?.length) {
      selectedQuantity =
        modifier.SelectedItemList?.reduce(
          (acc, item) =>
            acc + (!item.ItemSelectedQuantity ? 1 : item.ItemSelectedQuantity),
          0
        ) ?? 0
    }

    if (selectedQuantity < min) {
      return false
    } else if (max > 0 && selectedQuantity > max) {
      return false
    }

    return true
  }

  private getItemValue (itemList: ModifierItemRequestModel[]): number {
    return itemList.reduce(
      (agg: number, { Modifiers, ...rest }: ModifierItemRequestModel) => {
        const childQuantity = this.getItemValue(Modifiers ?? [])

        return (
          agg +
          (childQuantity + (rest.ItemPrice ?? 0)) * (rest.ItemQuantity ?? 0)
        )
      },
      0
    )
  }
}
