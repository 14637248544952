import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6063140"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["data-src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_load_image = _resolveComponent("vue-load-image")!

  return (!_ctx.background)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.image)
          ? (_openBlock(), _createBlock(_component_vue_load_image, { key: 0 }, {
              image: _withCtx(() => [
                _createElementVNode("img", {
                  class: _normalizeClass(_ctx.imageClass),
                  src: _ctx.image
                }, null, 10, _hoisted_1)
              ]),
              preloader: _withCtx(() => [
                (_ctx.placeholder)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: _normalizeClass(_ctx.placeholderClass ?? _ctx.imageClass),
                      src: `${_ctx.context.placeHolderUrl}`
                    }, null, 10, _hoisted_2))
                  : _createCommentVNode("", true)
              ]),
              error: _withCtx(() => [
                (_ctx.placeholder)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: _normalizeClass(_ctx.placeholderClass ?? _ctx.imageClass),
                      src: `${_ctx.context.placeHolderUrl}`
                    }, null, 10, _hoisted_3))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: _normalizeClass(_ctx.placeholderClass ?? _ctx.imageClass),
              src: `${_ctx.context.placeHolderUrl}`
            }, null, 10, _hoisted_4))
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.image)
          ? (_openBlock(), _createBlock(_component_vue_load_image, { key: 0 }, {
              image: _withCtx(() => [
                _createElementVNode("div", {
                  class: "img-background",
                  style: _normalizeStyle(`background-image: url(${_ctx.image})`),
                  "data-src": _ctx.image
                }, null, 12, _hoisted_5)
              ]),
              preloader: _withCtx(() => [
                _createElementVNode("div", {
                  class: "img-background",
                  style: _normalizeStyle(`background-image: url(${_ctx.context.placeHolderUrl})`)
                }, null, 4)
              ]),
              error: _withCtx(() => [
                _createElementVNode("div", {
                  class: "img-background",
                  style: _normalizeStyle(`background-image: url(${_ctx.context.placeHolderUrl})`)
                }, null, 4)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "img-background",
              style: _normalizeStyle(`background-image: url(${_ctx.context.placeHolderUrl})`)
            }, null, 4))
      ], 64))
}