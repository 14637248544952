enum ServiceConfig {
  CLIENT_LOGIN = 'client_login_user',
  CLIENT_SIGNUP_GUEST = 'client_signup_guest',
  CLIENT_VERIFY_LOGIN = 'client_verify_login',

  DIVISION_LIST = 'client_list_menu',
  DIVISION_GROUP_LIST = 'client_get_ListMenuCategories',
  DIVISION_GROUP = 'client_getMenuCategory',
  PRODUCT_SUGGESTED = 'client_list_suggested_groups',
  PRODUCT_FAVORITE = 'client_get_favoriteList',
  PRODUCT_BEST_SELLER = 'client_get_bestSellerList',
  PRODUCT_DIVISION_LIST = 'client_list_menu_groups',
  PRODUCT_GET = 'client_get_product',

  BANNER_LIST = 'client_banner_list',
  BRANCH_LIST = 'client_list_branches',

  CART_ADD_PRODUCT = 'client_update_cart',
  CART_UPDATE_PRODUCT = 'client_update_product',
  CART_REMOVE_PRODUCT = 'client_delete_cart',
  CART_REMOVE_PRODUCT_LIST = 'client_delete_cart_list',
  CART_GET = 'client_get_cart',
  CART_SET_INFO = 'client_set_cartInfo',

  CART_PLACE_VALIDATION = 'client_get_placeValidation',
  CART_PLACE_GUEST = 'client_place_orderAsGuest',

  ORDER_SEND_SMS = 'client_send_order_sms',
  ADD_REFUND = 'client_add_manual_refund',
  CLIENT_LIST_BY_PHONE = 'client_list_for_phone',

  KIOSK_TICKET_GET = 'client_get_kiosk_ticket',
  KIOSK_CONFIG_GET = 'client_get_kiosk_config'
}

export default ServiceConfig
