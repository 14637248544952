
import { Options, setup, Vue } from 'vue-class-component'
import Carousel from 'primevue/carousel'
import InputNumber from 'primevue/inputnumber'
import RadioButton from 'primevue/radiobutton'
import Checkbox from 'primevue/checkbox'
import Textarea from 'primevue/textarea'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import { computed, reactive, toRefs } from '@vue/reactivity'
import { inject, watch, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import ImageLoad from '@/components/ImageLoad.vue'
import {
  Dialogs,
  ProductDetailDialogState
} from '@/store/modules/dialog.module'
import { Dictionary, Nullable } from '@/utils/customtypes.util'
import { Actions } from '@/config/store.config'
import { Modifier, ModifierItem } from '@/models/menu/product.model'
import appconfigService from '@/services/appconfig/appconfig.service'
import ModuleConfig from '@/config/modules.config'

import ModifierItemRequestModel from '@/models/requests/modifieritemadd.request'
import ProductAddRequestModel from '@/models/requests/productadd.request'
import cartService from '@/services/cart/cart.service'
import store from '@/store'
import { CartProduct } from '@/models/cart/cart.model'
import ProductItem from '../products/ProductItem.vue'
import { nextTick } from 'vue'

interface ProductDetailState {
  visible: boolean
  selectedModifier: Nullable<Modifier>
  quantity: number
  comment: string
  ProductCommentsEnabled: boolean
  items: Dictionary<ProductItem>
}

@Options({
  name: 'product-detail',
  components: {
    Dialog,
    Carousel,
    InputNumber,
    RadioButton,
    Checkbox,
    Dropdown,
    Textarea,
    ImageLoad
  }
})
export default class ProductDetail extends Vue {
  context = setup(() => {
    const store = useStore()
    const flavor = inject<string>('FLAVOR')
    const state = reactive<ProductDetailState>({
      visible: false,
      selectedModifier: null,
      quantity: 1,
      comment: '',
      ProductCommentsEnabled: true,
      items: {}
    })

    const isVisible = computed<boolean>(() => {
      return store.getters.isVisible(Dialogs.ProductDetail)
    })

    const data = computed<Nullable<ProductDetailDialogState>>(() =>
      store.getters.dialogData(Dialogs.ProductDetail)
    )

    const reset = (data: Nullable<ProductDetailDialogState>) => {
      state.quantity = data?.orderProduct?.ProductQuantity ?? 1
      state.comment = data?.orderProduct?.ProductComments ?? ''

      if (!data) {
        return
      }

      if (data.product?.ModifierList?.[0]) {
        state.selectedModifier = data?.product.ModifierList[0]
      } else {
        state.selectedModifier = null
      }

      data.product?.ModifierList?.forEach((modifier) => {
        modifier.ItemList?.forEach((item) => {
          item.ItemSelectedQuantity = item.ItemSelectedQuantity ?? 0
        })
      })
    }

    const images = computed<string[]>(() => {
      const imageList =
        data.value?.product?.ProductImageList?.map(
          (p) => p.ProductImageUrl ?? ''
        ) ?? []

      data.value?.product?.ProductImage &&
        imageList.unshift(data.value.product.ProductImage)
      return imageList
    })

    onMounted(async () => {
      const appconfig = await appconfigService.get()
      state.ProductCommentsEnabled = appconfig.isModuleEnabled(
        ModuleConfig.PRODUCT_COMMENTS_ENABLED
      )
    })

    watch(
      () => isVisible.value,
      () => {
        state.visible = isVisible.value
      }
    )

    watch(
      () => state.visible,
      (value) => {
        if (!value) {
          store.dispatch(Actions.HIDE_ALL_DIALOG)
        }
      }
    )

    watch(
      () => data.value,
      () => {
        reset(data.value)
      }
    )

    const productTotal = computed<number>(() => {
      if (!data.value?.product) {
        return 0
      }

      const totalModifier =
        data.value.product.ModifierList?.flatMap((modifier) => {
          if (modifier.SelectedItem) {
            return [modifier.SelectedItem]
          }

          return modifier.SelectedItemList ?? []
        }).reduce(
          (agg, item) =>
            agg +
            (item.ItemPrice ?? 0) *
              (item.ItemSelectedQuantity ? item.ItemSelectedQuantity : 1),
          0
        ) ?? 0

      return (
        ((data.value.product.ProductPrice ?? 0) + totalModifier) *
        state.quantity
      )
    })

    return {
      ...toRefs(state),
      flavor,
      data,
      images,
      productTotal
    }
  })

  setSelectedModifier (modifier: Modifier) {
    this.collapseNestedModifiers(null)
    this.context.selectedModifier = modifier
  }

  sendProduct (): void {
    const product = this.context.data?.product
    if (!product) {
      return
    }

    const validModifierList =
      product.ModifierList?.filter(this.isModifierValid) ?? []

    if (validModifierList.length !== product.ModifierList?.length) {
      store.dispatch(Actions.SHOW_ERROR, {
        title: 'Verifica tus modificadores',
        message:
          'Debes verificar la configración de los modificadores obligatorios',
        type: ''
      })
      return
    }

    const modifierList =
      validModifierList.flatMap<ModifierItemRequestModel>((modifier) => {
        if (modifier.SelectedItem) {
          return [
            {
              ItemId: modifier.SelectedItem.ItemId,
              ItemName: modifier.SelectedItem.ItemName,
              ItemPrice: modifier.SelectedItem.ItemPrice,
              ItemQuantity: 1,
              ModifierId: modifier.ModifierId,
              Modifiers: modifier.SelectedItem.Modifiers ?? []
            }
          ]
        }

        return (
          modifier.SelectedItemList?.map((item) => ({
            ItemId: item.ItemId,
            ItemName: item.ItemName,
            ItemPrice: item.ItemPrice,
            ItemQuantity: item.ItemSelectedQuantity,
            ModifierId: modifier.ModifierId,
            Modifiers: item.Modifiers ?? []
          })) ?? []
        )
      }) ?? []

    const productAddElement: ProductAddRequestModel = {
      ProductId: product.ProductId,
      ProductName: product.ProductName,
      ProductQuantity: this.context.quantity,
      ProductPrice: product.ProductPrice,
      ProductComments: this.context.comment,
      DivisionGroupId: this.context.data?.divisionGroupId,
      Modifiers: modifierList
    }

    if (this.context.data?.orderProduct) {
      // productAddElement.DivisionGroupId = this.$props.orderProduct?.DivisionGroup?.DivisionGroupId
      this.updateProduct(productAddElement, this.context.data?.orderProduct)
    } else {
      this.addProduct(productAddElement)
    }
  }

  private addProduct (product: ProductAddRequestModel): void {
    cartService
      .addProduct(product)
      .then(() => {
        store.dispatch(Actions.UPDATE_CART)
        this.hide()
      })
      .catch((err: Error) => {
        store.dispatch(Actions.SHOW_ERROR, {
          title: 'Lo sentimos',
          message: err.message,
          type: ''
        })
      })
  }

  private updateProduct (
    product: ProductAddRequestModel,
    orderProduct: CartProduct
  ): void {
    cartService
      .updateProduct(orderProduct.OrderProductId, product)
      .then(() => {
        store.dispatch(Actions.UPDATE_CART)
        this.hide()
      })
      .catch((err: Error) => {
        store.dispatch(Actions.SHOW_ERROR, {
          title: 'Lo sentimos',
          message: err.message,
          type: ''
        })
      })
  }

  getOptionList (item: ModifierItem, modifier: Modifier) {
    let itemsCount = 10

    if ((item.ItemMaxQuantity ?? 0) > 0) {
      itemsCount = item.ItemMaxQuantity ?? 0
    }

    if ((modifier.ModifierMax ?? 0) > 0) {
      itemsCount = modifier.ModifierMax ?? 0
    }

    return [...Array(itemsCount).keys()].map((i) => i + 1)
  }

  public onItemQuantityChange (item: ModifierItem): void {
    if (!this.context.selectedModifier) {
      return
    }

    const index =
      this.context.selectedModifier.SelectedItemList?.indexOf(item) ?? -1
    if (item.ItemSelectedQuantity > 0 && index < 0) {
      this.context.selectedModifier.SelectedItemList?.push(item)
    }

    if (item.ItemSelectedQuantity <= 0 && index >= 0) {
      this.context.selectedModifier.SelectedItemList?.splice(index, 1)
    }
  }

  public onItemSelected (item: ModifierItem, modifier: Modifier): void {
    if (!modifier) {
      return
    }

    const max = modifier.ModifierMax ?? 0
    const current = modifier.SelectedItemList?.length ?? 0
    const index = modifier.SelectedItemList?.indexOf(item) ?? -1
    if (index >= 0 && max > 0 && current > max) {
      modifier.SelectedItemList?.splice(index, 1)
    }
  }

  public isModifierValid (modifier: Modifier): boolean {
    if (!modifier) {
      return false
    }

    const min = modifier.ModifierMin ?? 0
    const max = modifier.ModifierMax ?? 0

    let selectedQuantity = modifier.SelectedItem ? 1 : 0

    if (modifier.SelectedItemList?.length) {
      selectedQuantity =
        modifier.SelectedItemList?.reduce(
          (acc, item) =>
            acc + (!item.ItemSelectedQuantity ? 1 : item.ItemSelectedQuantity),
          0
        ) ?? 0
    }

    if (selectedQuantity < min) {
      return false
    } else if (max > 0 && selectedQuantity > max) {
      return false
    }

    return true
  }

  public getModifierRequirementText (modifier: Modifier): string {
    if (!modifier) {
      return ''
    }

    if (
      (modifier.ModifierMin ?? 0) === 0 &&
      (modifier.ModifierMax ?? 0) === 0
    ) {
      return 'Opcional'
    }

    if (
      modifier.ModifierMin === modifier.ModifierMax ||
      !modifier.ModifierMax
    ) {
      return `${modifier.ModifierMin} ${
        (modifier.ModifierMin ?? 0) > 1 ? 'obligatorios' : 'obligatorio'
      }`
    }

    if (!modifier.ModifierMin) {
      return `Máximo ${modifier.ModifierMax}`
    }

    return `${modifier.ModifierMin} ${
      (modifier.ModifierMin ?? 0) > 1 ? 'obligatorios' : 'obligatorio'
    }, Máximo ${modifier.ModifierMax}`
  }

  public hide (): void {
    store.dispatch(Actions.HIDE_ALL_DIALOG)
  }

  public getMaxInput (item: ModifierItem, modifier: Modifier) {
    const itemMax =
      (item?.ItemMaxQuantity ?? 0) === 0 ? 99 : item?.ItemMaxQuantity ?? 0

    if (!modifier.ModifierMax) {
      return itemMax
    }

    const currentSelectedCount =
      modifier.SelectedItemList?.filter(
        (i) => i.ItemId !== item.ItemId
      )?.reduce((count, item) => {
        return count + (item.ItemSelectedQuantity ?? 0)
      }, 0) ?? 0

    const availableCount = Math.max(
      0,
      (modifier.ModifierMax ?? 0) - currentSelectedCount
    )
    return Math.min(availableCount, itemMax)
  }

  public itemExpanded (sourceItem: Nullable<ModifierItem>) {
    this.collapseNestedModifiers(sourceItem)
    nextTick(() => {
      const itemsContainer = document.getElementById('items-container')
      const expandedOptions = document.querySelectorAll(
        '.option.nested-option.nested-option-expanded'
      )

      const lastExpandedOption = expandedOptions[expandedOptions.length - 1]
      if (lastExpandedOption) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const containerOffset = (itemsContainer as any)?.offsetTop ?? 0
        const offsetTop =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (lastExpandedOption as any)?.offsetTop - containerOffset

        itemsContainer?.scrollTo({
          top: offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      }
    })
  }

  public collapseNestedModifiers (sourceItem: Nullable<ModifierItem>) {
    this.context.selectedModifier?.ItemList?.forEach((item) =>
      this.context.items[item.ItemId ?? 0]?.closeNestedModifiers(sourceItem)
    )
  }
}
