import store from '@/store'
import {
  PaymentInputModel,
  PaymentResponseModel,
  RefundInputModel,
  RefundResponseModel
} from './models/payment.model'
import { FiservUtil } from './providers/fiserv.util'
import { MitWebUtil } from './providers/mit-web.util'
import { MitUtil } from './providers/mit.util'

export enum PaymentProviderType {
  MIT_WEB = 'mit',
  MIT_WEB_EXPLICIT = 'mit-web',
  MIT = 'mit-dll',
  FISERV = 'fiserv'
}

export interface PaymentProviderData {
  type: PaymentProviderType
}

export interface PaymentProvider {
  get initOnStart(): boolean
  get requiresInteractiveCancel(): boolean

  init(): Promise<object>
  executePayment(data: PaymentInputModel): Promise<PaymentResponseModel>
  cancel(data: RefundInputModel): Promise<RefundResponseModel>
}

export class PaymentProviderFactory {
  public static GetForType (type: PaymentProviderType): PaymentProvider {
    switch (type) {
      case PaymentProviderType.FISERV:
        return new FiservUtil()
      case PaymentProviderType.MIT:
        return new MitUtil()
      default:
        return new MitWebUtil()
    }
  }

  public static GetProvider (): PaymentProvider {
    const type = store.getters.pinPadType
    return PaymentProviderFactory.GetForType(
      type ?? PaymentProviderType.MIT_WEB
    )
  }
}

class PaymentProviderSingleton {
  private static instance: PaymentProvider

  public static getInstance (): PaymentProvider {
    if (!PaymentProviderSingleton.instance) {
      PaymentProviderSingleton.instance = PaymentProviderFactory.GetProvider()
    }

    return PaymentProviderSingleton.instance
  }
}

export const getPaymentUtil = PaymentProviderSingleton.getInstance
