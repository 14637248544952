
import { Options, setup, prop, Vue } from 'vue-class-component'
import { Modifier, ModifierItem } from '@/models/menu/product.model'

import InputNumber from 'primevue/inputnumber'
import RadioButton from 'primevue/radiobutton'
import Checkbox from 'primevue/checkbox'
import ProductItem from './ProductItem.vue'
import { reactive, toRefs } from 'vue'
import { Nullable } from 'primevue/ts-helpers'

class ProductModifierItemProps {
  modifier = prop<Modifier>({ required: true })
}

interface ProductItemState {
  childItems: ProductItem[]
}

@Options({
  name: 'product--modifier-item',
  emits: ['item-expanded'],
  components: {
    InputNumber,
    RadioButton,
    Checkbox
  }
})
export default class ProductModifierItem extends Vue.with(
  ProductModifierItemProps
) {
  context = setup(() => {
    const state = reactive<ProductItemState>({
      childItems: []
    })

    return {
      ...toRefs(state)
    }
  })

  public itemExpanded (sourceItem: Nullable<ModifierItem>) {
    this.collapseNestedModifiers(sourceItem)
    this.$emit('item-expanded', sourceItem)
  }

  public collapseNestedModifiers (sourceItem: Nullable<ModifierItem>) {
    this.context.childItems.forEach((item) =>
      item.closeNestedModifiers(sourceItem)
    )
  }

  public cleanNestedModifiers (sourceItem: Nullable<ModifierItem>) {
    this.context.childItems.forEach((item) =>
      item.cleanNestedModifiers(sourceItem)
    )
  }

  public fillNestedModifiers (sourceItem: Nullable<ModifierItem>) {
    const modifier = this.$props.modifier
    const itemList = sourceItem?.Modifiers?.filter(
      (i) => i.ModifierId === modifier.ModifierId
    )

    if (!itemList?.length) return

    this.context.childItems
      .filter((child) => itemList.some((i) => i.ItemId === child.item.ItemId))
      .forEach((child) => {
        const item = itemList?.find((i) => i.ItemId === child.item.ItemId)
        child.item.Modifiers = item?.Modifiers ?? []
        child.item.ItemSelectedQuantity = item?.ItemQuantity ?? 1

        child.setSelected(child.item)
      })
  }

  public getModifierRequirementText (modifier: Modifier): string {
    if (!modifier) {
      return ''
    }

    if (!((modifier.ModifierMin ?? 0) > 0 || (modifier.ModifierMax ?? 0) > 0)) {
      return 'Opcional'
    }

    if (
      modifier.ModifierMin === modifier.ModifierMax ||
      !modifier.ModifierMax
    ) {
      return `${modifier.ModifierMin} ${
        (modifier.ModifierMin ?? 0) > 1 ? 'obligatorios' : 'obligatorio'
      }`
    }

    if (!modifier.ModifierMin) {
      return `Máximo ${modifier.ModifierMax}`
    }

    return `${modifier.ModifierMin} ${
      (modifier.ModifierMin ?? 0) > 1 ? 'obligatorios' : 'obligatorio'
    }, Máximo ${modifier.ModifierMax}`
  }
}
