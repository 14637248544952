
import { Options, setup, Vue } from 'vue-class-component'
import Dialog from 'primevue/dialog'
import { computed, reactive, toRefs } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { Dialogs, UnlockDialogState } from '@/store/modules/dialog.module'
import { Nullable } from '@/utils/customtypes.util'
import { Actions } from '@/config/store.config'
import store from '@/store'
import { Md5 } from 'ts-md5'
import { LocalManagementUtil } from '@/utils/local-management.util'

interface UnlockState {
  visible: boolean
  pin: string
  maxLength: number
}

@Options({
  name: 'unlock-dialog',
  components: {
    Dialog
  }
})
export default class UnlockDialog extends Vue {
  context = setup(() => {
    const store = useStore()
    const state = reactive<UnlockState>({
      visible: false,
      pin: '',
      maxLength: 8
    })

    const isValid = computed<boolean>(
      () => state.pin.length >= state.maxLength
    )

    const isVisible = computed<boolean>(() => {
      return store.getters.isVisible(Dialogs.Unlock)
    })

    const data = computed<Nullable<UnlockDialogState>>(() =>
      store.getters.dialogData(Dialogs.Unlock)
    )

    watch(
      () => isVisible.value,
      () => {
        state.visible = isVisible.value
        state.pin = ''
      }
    )

    watch(
      () => state.visible,
      (value) => {
        if (!value) {
          this.hide()
        }
      }
    )

    return {
      ...toRefs(state),
      data,
      isValid
    }
  })

  public addNumber (number: number) {
    if (number === -1) {
      this.context.pin = this.context.pin.slice(0, -1)
    } else {
      if (this.context.pin.length < this.context.maxLength) {
        this.context.pin += number
      }
    }

    setTimeout(() => {
      if (this.context.isValid) {
        this.savePin(this.context.pin)
      }
    }, 100)
  }

  public async savePin (pin: string) {
    const hash = Md5.hashAsciiStr(pin)
    const unlockPin = store.getters.unlockPin

    if (hash !== unlockPin) {
      this.context.pin = ''
      store.dispatch(Actions.SHOW_ERROR, {
        title: 'Contraseña incorrecta',
        message: 'La contraseña proporcionada es incorrecta',
        type: ''
      })
      return
    }

    const localManagementUtil = new LocalManagementUtil()
    localManagementUtil
      .unlock()
      .then(() => {
        this.hide()
      })
      .catch((err) => {
        this.context.pin = ''
        store.dispatch(Actions.SHOW_ERROR, {
          title: 'Lo sentimos',
          message: err,
          type: ''
        })
      })
  }

  public hide (): void {
    const callback = this.context.data?.callback
    callback && callback()

    this.context.pin = ''
    store.dispatch(Actions.HIDE_ALL_DIALOG)
  }
}
