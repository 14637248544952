import GeneralConfig from '@/config/general.config'
import { Nullable } from '@/utils/customtypes.util'
import SerializableObject from '@/utils/models/serializableobject.model'

export interface AppConfigMail {
  AppKeyId?: Nullable<string>
  AppName?: Nullable<string>
  AppIconUrl?: Nullable<string>
  EnvironmentKey?: Nullable<string>
  EnvironmentName?: Nullable<string>
}

type AppConfigDictionary = { [key: string] : string }

export interface AppConfigData {
  services: AppConfigDictionary
  modules: AppConfigDictionary
  apis: AppConfigDictionary
  constants: AppConfigDictionary
  appData: AppConfigDictionary
  texts: AppConfigDictionary
}

export default class AppConfig extends SerializableObject {
  appVersion: Nullable<number> = null
  timestamp: Nullable<number> = null

  Main: Nullable<AppConfigMail> = null
  DataConfig: Nullable<AppConfigData> = null

  public getServiceUrl (key: string) : string {
    return this.DataConfig?.services?.[key] ?? ''
  }

  public isModuleEnabled (key: string) : boolean {
    return (this.DataConfig?.modules?.[key] ?? 'false') === 'true'
  }

  public textOption (key: string) : string {
    return (this.DataConfig?.texts?.[key] ?? '')
  }

  public isValid () : boolean {
    const isInTime = !!this.timestamp && (new Date().getTime() - this.timestamp) <= GeneralConfig.ALLSECTIONS_MAX_MINUTES * 60_000
    return GeneralConfig.APP_VERSION === this.appVersion && isInTime
  }
}
