import { Actions, Mutations } from '@/config/store.config'
import Product from '@/models/menu/product.model'
import BannerResponse from '@/models/responses/banner.response'
import { Nullable } from '@/utils/customtypes.util'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

const FAVORITES_CONFIG_KEY = 'favoritesStorageData'
const BANNERS_CONFIG_KEY = 'bannersStorageData'

export interface MenuStateInfo {
  favoriteProducts: Product[]
  banners: Nullable<BannerResponse>
}

@Module
export default class MenuModule extends VuexModule implements MenuStateInfo {
  favoriteProducts = ((): Product[] => {
    try {
      return JSON.parse(localStorage.getItem(FAVORITES_CONFIG_KEY) ?? '[]')
    } catch (err) {
      return []
    }
  })()

  banners = ((): Nullable<BannerResponse> => {
    try {
      return JSON.parse(localStorage.getItem(BANNERS_CONFIG_KEY) ?? 'null')
    } catch (err) {
      return null
    }
  })()

  get favoriteProductList () : Product[] {
    return this.favoriteProducts
  }

  get bannerList () : Nullable<BannerResponse> {
    return this.banners
  }

  @Mutation
  [Mutations.SET_FAVORITE_PRODUCTS] (products: Product[]): void {
    this.favoriteProducts = products
    localStorage.setItem(FAVORITES_CONFIG_KEY, JSON.stringify(products))
  }

  @Mutation
  [Mutations.SET_BANNERS] (bannerList: BannerResponse): void {
    this.banners = bannerList
    localStorage.setItem(BANNERS_CONFIG_KEY, JSON.stringify(bannerList))
  }

  @Action
  [Actions.SET_FAVORITE_PRODUCTS] (products: Product[]): void {
    this.context.commit(Mutations.SET_FAVORITE_PRODUCTS, products)
  }

  @Action
  [Actions.SET_BANNERS] (bannerList: BannerResponse): void {
    this.context.commit(Mutations.SET_BANNERS, bannerList)
  }

  @Action
  [Actions.CLEAR_MENU_CACHE] (): void {
    this.context.commit(Mutations.SET_FAVORITE_PRODUCTS, [])
  }
}
