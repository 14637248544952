import { Actions, Mutations } from '@/config/store.config'
import Kiosk from '@/models/kiosk/kiosk.model'
import { Nullable } from '@/utils/customtypes.util'
import { PaymentProviderType } from '@/utils/payment/payment-provider'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Md5 } from 'ts-md5'

const BRANCH_CONFIG_KEY = 'BRANCH_CONFIG'
const UNLOCK_PIN_CONFIG_KEY = 'UNLOCK_PIN_CONFIG'
const PINPAD_TYPE_KEY = 'PINPAD_TYPE'
const PINPAD_CONFIG_FISERV_KEY = 'PINPAD_CONFIG_FISERV'
const PINPAD_CONFIG_MIT_KEY = 'PINPAD_CONFIG_MIT'
const PINPAD_CONFIG_MIT_WEB_KEY = 'PINPAD_CONFIG'
const PRINTER_CONFIG = 'PRINTER_CONFIG'

export interface PinPadConfigLocal {
  endpoint: Nullable<string>
}

export interface PinPadConfigMitWeb {
  id: string
  username: string
  password: string
  environment: string
  ignoreOnStart: boolean
}

export interface PrinterConfig {
  name: Nullable<string>
  type: Nullable<string>
}

export interface ConfigStateInfo {
  BranchId: Nullable<number>
  UnlockPin: Nullable<string>

  PinPadType: Nullable<string>
  PinpadConfigFiserv: PinPadConfigLocal
  PinpadConfigMitDll: PinPadConfigLocal
  PinpadConfigMitWeb: PinPadConfigMitWeb

  PrinterConfig: PrinterConfig
}

@Module
export default class ConfigModule extends VuexModule implements ConfigStateInfo {
  get isConfigured (): boolean {
    if (!this.BranchId) return false

    if (
      (this.PinPadType === PaymentProviderType.MIT_WEB ||
        this.PinPadType === PaymentProviderType.MIT_WEB_EXPLICIT) &&
      (!this.PinpadConfigMitWeb?.id ||
        !this.PinpadConfigMitWeb?.username ||
        !this.PinpadConfigMitWeb?.password ||
        !this.PinpadConfigMitWeb?.environment)
    ) {
      return false
    }

    if (
      this.PinPadType === PaymentProviderType.FISERV &&
      !this.PinpadConfigFiserv?.endpoint
    ) {
      return false
    }

    if (
      this.PinPadType === PaymentProviderType.MIT &&
      !this.PinpadConfigMitDll?.endpoint
    ) {
      return false
    }

    return true
  }

  BranchId = ((): Nullable<number> => {
    try {
      const value = localStorage.getItem(BRANCH_CONFIG_KEY)
      if (!value) return null
      return +value
    } catch (err) {
      return null
    }
  })()

  get branchId (): Nullable<number> {
    return this.BranchId
  }

  UnlockPin = ((): Nullable<string> => {
    try {
      const value = localStorage.getItem(UNLOCK_PIN_CONFIG_KEY)
      if (!value) return null
      return value
    } catch (err) {
      return null
    }
  })()

  get unlockPin (): string {
    return this.UnlockPin ?? Md5.hashAsciiStr('11111111')
  }

  PinPadType = ((): Nullable<string> => {
    try {
      const value = localStorage.getItem(PINPAD_TYPE_KEY)
      if (!value) return null
      return value
    } catch (err) {
      return null
    }
  })()

  get pinPadType (): Nullable<string> {
    return this.PinPadType
  }

  PinpadConfigFiserv = ((): PinPadConfigLocal => {
    try {
      const json = JSON.parse(
        localStorage.getItem(PINPAD_CONFIG_FISERV_KEY) ?? 'null'
      )

      return { endpoint: json?.endpoint }
    } catch (err) {
      return { endpoint: null }
    }
  })()

  get pinpadConfigFiserv (): PinPadConfigLocal {
    return this.PinpadConfigFiserv
  }

  PinpadConfigMitDll = ((): PinPadConfigLocal => {
    try {
      const json = JSON.parse(
        localStorage.getItem(PINPAD_CONFIG_MIT_KEY) ?? 'null'
      )

      return { endpoint: json?.endpoint }
    } catch (err) {
      return { endpoint: null }
    }
  })()

  get pinpadConfigMitDll (): PinPadConfigLocal {
    return this.PinpadConfigMitDll
  }

  PinpadConfigMitWeb = ((): PinPadConfigMitWeb => {
    try {
      const json = JSON.parse(
        localStorage.getItem(PINPAD_CONFIG_MIT_WEB_KEY) ?? 'null'
      )

      return {
        id: json?.id,
        username: json?.username ?? '',
        password: json?.password ?? '',
        environment: json?.environment ?? '',
        ignoreOnStart: json?.ignoreOnStart ?? true
      }
    } catch (err) {
      return {
        id: '',
        username: '',
        password: '',
        environment: '',
        ignoreOnStart: true
      }
    }
  })()

  get pinpadConfigMitWeb (): PinPadConfigMitWeb {
    return this.PinpadConfigMitWeb
  }

  PrinterConfig = ((): PrinterConfig => {
    try {
      const json = JSON.parse(localStorage.getItem(PRINTER_CONFIG) ?? 'null')

      return {
        type: json?.type,
        name: json?.name
      }
    } catch (err) {
      return {
        type: null,
        name: null
      }
    }
  })()

  get printerConfig (): PrinterConfig {
    return this.PrinterConfig
  }

  @Mutation
  [Mutations.SET_BRANCH_ID] (id: Nullable<number>): void {
    this.BranchId = id
    localStorage.setItem(BRANCH_CONFIG_KEY, this.BranchId?.toString() ?? '')
  }

  @Mutation
  [Mutations.SET_UNLOCK_PIN] (pin: Nullable<string>): void {
    this.UnlockPin = pin
    localStorage.setItem(UNLOCK_PIN_CONFIG_KEY, this.UnlockPin ?? '')
  }

  @Mutation
  [Mutations.SET_PINPAD_TYPE] (type: Nullable<string>): void {
    this.PinPadType = type
    localStorage.setItem(PINPAD_TYPE_KEY, this.PinPadType ?? '')
  }

  @Mutation
  [Mutations.SET_PINPAD_FISERV] (config: PinPadConfigLocal): void {
    this.PinpadConfigFiserv = config
    localStorage.setItem(
      PINPAD_CONFIG_FISERV_KEY,
      JSON.stringify(this.PinpadConfigFiserv)
    )
  }

  @Mutation
  [Mutations.SET_PINPAD_MIT_DLL] (config: PinPadConfigLocal): void {
    this.PinpadConfigMitDll = config
    localStorage.setItem(
      PINPAD_CONFIG_MIT_KEY,
      JSON.stringify(this.PinpadConfigMitDll)
    )
  }

  @Mutation
  [Mutations.SET_PINPAD_MIT_WEB] (config: PinPadConfigMitWeb): void {
    this.PinpadConfigMitWeb = config
    localStorage.setItem(
      PINPAD_CONFIG_MIT_WEB_KEY,
      JSON.stringify(this.PinpadConfigMitWeb)
    )
  }

  @Mutation
  [Mutations.SET_PRINTER_CONFIG] (config: PrinterConfig): void {
    this.PrinterConfig = config
    localStorage.setItem(PRINTER_CONFIG, JSON.stringify(this.PrinterConfig))
  }

  @Action
  [Actions.SET_GENERAL_CONFIG] (config: Kiosk): void {
    console.log(config)
    this.context.commit(Mutations.SET_BRANCH_ID, config.BranchId)
    this.context.commit(Mutations.SET_CART_BRANCH, this.BranchId)

    this.context.commit(Mutations.SET_UNLOCK_PIN, config.KioskUnlockPin)
    this.context.commit(Mutations.SET_PINPAD_TYPE, config.KioskPinpadType)

    const pinpadLocalConfig: PinPadConfigLocal = {
      endpoint: config.KioskPinpadHost
    }

    this.context.commit(Mutations.SET_PINPAD_FISERV, pinpadLocalConfig)
    this.context.commit(Mutations.SET_PINPAD_MIT_DLL, pinpadLocalConfig)

    const mitPinpadConfig: PinPadConfigMitWeb = {
      id: config.KioskPinpadMitId ?? '',
      username: config.KioskPinpadMitUser ?? '',
      password: config.KioskPinpadMitPassword ?? '',
      environment: config.KioskPinpadMitEnv ?? '',
      ignoreOnStart: true
    }

    this.context.commit(Mutations.SET_PINPAD_MIT_WEB, mitPinpadConfig)

    const printerConfig: PrinterConfig = {
      name: config.KioskPrinterName,
      type: config.KioskPrinterType
    }

    this.context.commit(Mutations.SET_PRINTER_CONFIG, printerConfig)
  }
}
