import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ProductDetail = _resolveComponent("ProductDetail")!
  const _component_ErroDialog = _resolveComponent("ErroDialog")!
  const _component_UnlockDialog = _resolveComponent("UnlockDialog")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_ProductDetail),
    _createVNode(_component_ErroDialog),
    _createVNode(_component_UnlockDialog),
    _createVNode(_component_confirm_dialog)
  ], 64))
}