import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-426a2500"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nested-modifiers-container" }
const _hoisted_2 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_ProductModifierItem = _resolveComponent("ProductModifierItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.modifier?.ModifierMin === 1 && _ctx.modifier?.ModifierMax === 1)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["option", {
      'nested-option': (_ctx.item.ModifierList?.length ?? 0) > 0,
      'nested-option-expanded': _ctx.context.expanded
    }])
        }, [
          _createVNode(_component_RadioButton, {
            name: `item_${_ctx.modifier.ModifierId}`,
            value: _ctx.item,
            modelValue: _ctx.modifier.SelectedItem,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modifier.SelectedItem) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateItem(_ctx.item)))
          }, null, 8, ["name", "value", "modelValue"]),
          _createTextVNode(" " + _toDisplayString(_ctx.item.ItemName) + " ", 1),
          _createElementVNode("span", null, "$" + _toDisplayString(_ctx.context.itemPrice?.toFixed(2)), 1),
          ((_ctx.item.ModifierList?.length ?? 0) > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn-expand",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleNestedModifiers()))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {
          'pi-chevron-down': _ctx.context.expanded,
          'pi-chevron-right': !_ctx.context.expanded
        }])
                }, null, 2)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (
      _ctx.modifier?.ModifierIsMultipleQuantity &&
      !(_ctx.modifier?.ModifierMin === 1 && _ctx.modifier?.ModifierMax === 1)
    )
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          class: _normalizeClass(["option", {
      'nested-option': (_ctx.item.ModifierList?.length ?? 0) > 0,
      'nested-option-expanded': _ctx.context.expanded
    }])
        }, [
          _createVNode(_component_InputNumber, {
            modelValue: _ctx.item.ItemSelectedQuantity,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.ItemSelectedQuantity) = $event)),
            showButtons: "",
            min: 0,
            max: _ctx.getMaxInput(_ctx.item, _ctx.modifier),
            buttonLayout: "horizontal",
            decrementButtonClass: "p-button-danger",
            incrementButtonClass: "p-button-success",
            incrementButtonIcon: "pi pi-plus",
            decrementButtonIcon: "pi pi-minus",
            readonly: true,
            onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onItemQuantityChange(_ctx.item))),
            onFocus: _cache[5] || (_cache[5] = ($event: any) => (($event.target as any)?.blur()))
          }, null, 8, ["modelValue", "max"]),
          _createTextVNode(" " + _toDisplayString(_ctx.item.ItemName) + " ", 1),
          _createElementVNode("span", null, "$" + _toDisplayString(_ctx.context.itemPrice?.toFixed(2)), 1),
          ((_ctx.item.ModifierList?.length ?? 0) > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn-expand",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleNestedModifiers()))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {
          'pi-chevron-down': _ctx.context.expanded,
          'pi-chevron-right': !_ctx.context.expanded
        }])
                }, null, 2)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (
      !_ctx.modifier?.ModifierIsMultipleQuantity &&
      !(_ctx.modifier?.ModifierMin === 1 && _ctx.modifier?.ModifierMax === 1)
    )
      ? (_openBlock(), _createElementBlock("label", {
          key: 2,
          class: _normalizeClass(["option", {
      'nested-option': (_ctx.item.ModifierList?.length ?? 0) > 0,
      'nested-option-expanded': _ctx.context.expanded
    }])
        }, [
          _createVNode(_component_Checkbox, {
            name: `item_${_ctx.modifier.ModifierId}`,
            value: _ctx.item,
            modelValue: _ctx.modifier.SelectedItemList,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modifier.SelectedItemList) = $event)),
            onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onItemSelected(_ctx.item, _ctx.modifier)))
          }, null, 8, ["name", "value", "modelValue"]),
          _createTextVNode(" " + _toDisplayString(_ctx.item.ItemName) + " ", 1),
          _createElementVNode("span", null, "$" + _toDisplayString(_ctx.context.itemPrice?.toFixed(2)), 1),
          ((_ctx.item.ModifierList?.length ?? 0) > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn-expand",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.toggleNestedModifiers()))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {
          'pi-chevron-down': _ctx.context.expanded,
          'pi-chevron-right': !_ctx.context.expanded
        }])
                }, null, 2)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item?.ModifierList, (modifier) => {
        return (_openBlock(), _createBlock(_component_ProductModifierItem, {
          key: modifier.ModifierId,
          ref_for: true,
          ref: (el) => _ctx.context.childModifiers.push(el),
          modifier: modifier,
          onItemExpanded: _ctx.onItemExpanded
        }, null, 8, ["modifier", "onItemExpanded"]))
      }), 128)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.closeNestedModifiers(null))),
          class: "cancel"
        }, " Cancelar "),
        _createElementVNode("button", {
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.confirmNestedModifiers()))
        }, "Confirmar")
      ])
    ], 512), [
      [_vShow, _ctx.context.expanded]
    ])
  ], 64))
}